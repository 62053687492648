<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block summary">
          <div class="h4 m-0">{{ $t('general.step3') }}</div>
          <small class="text-muted">{{ $t('general.summary') }}</small>
          <h5 class="mt-1 mb-1">{{ $t('firewall.firewallSettings') }}</h5>
          <p class="">
            {{ $t('general.name') }}:
            <span class="text-info">{{ newFirewallData.name }}</span>
          </p>
          <p class="">
            {{ $t('firewall.policy') }}:
            <span class="text-info">
              <span v-if="!newFirewallData.policy">—</span>
              <span v-else>{{ newFirewallData.policy }}</span>
            </span>
          </p>
          <p class="">
            {{ $t('firewall.direction') }}:
            <span class="text-info">{{ getDirectionTypeWithI18n(newFirewallData.direction) }}</span>
          </p>
          <h6 class="mt-1 mb-1">{{ $t('general.rules') }}</h6>
          <div class="row">
            <div class="rules col-lg-12">
              <div
                class="card not-edit animated fadeIn fadeOut"
                v-if="newFirewallData.rules.length"
                :data-index="index"
                v-for="(rule, index) in newFirewallData.rules"
                :class="{
                  'border-danger': rule.jump === 'DROP',
                  'border-secondary': rule.jump === 'RETURN',
                  'border-success': rule.jump === 'ACCEPT'
                }"
              >
                <div class="card-body">
                  <div class="h1 text-right mb-4">
                    <small class="text-muted mr-2"># {{ index + 1 }}</small>
                    <!--<i class="icon-check text-success" v-if="rule.jump === 'ACCEPT'"></i>-->
                    <!--<i class="icon-close text-danger" v-if="rule.jump === 'DROP'"></i>-->
                  </div>
                  <div class="h4 mb-0">
                    <span v-if="rule.jump">{{ rule.jump }}</span>
                  </div>
                  <small class="text-muted text-uppercase font-weight-bold">{{ rule.protocol }}</small>
                  <small class="text-muted text-uppercase font-weight-bold">
                    / {{ $t('firewall.ruleIPProtocol') }}:
                    <span v-if="rule.ip_protocol">{{ rule.ip_protocol }}</span>
                    <span v-else>—</span>
                  </small>
                  <div class="row" v-if="rule.destination.length || rule.source.length">
                    <div class="col-lg-6">
                      <div class="h6 mb-q mt-1">{{ $t('firewall.fromMAC') }}</div>
                      <small
                        v-if="rule.source.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                        v-for="mac in rule.source"
                      >
                        {{ mac }}
                      </small>
                      <small v-if="!rule.source.length" class="text-muted text-uppercase font-weight-bold rule-value">
                        -
                      </small>
                    </div>
                    <div class="col-lg-6">
                      <div class="h6 mb-q mt-1">{{ $t('firewall.toMAC') }}</div>
                      <small
                        v-if="rule.destination.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                        v-for="mac in rule.destination"
                      >
                        {{ mac }}
                      </small>
                      <small
                        v-if="!rule.destination.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                      >
                        -
                      </small>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="rule.protocol === 'ipv4' && (rule.ip_destination.length || rule.ip_source.length)"
                  >
                    <div class="col-lg-6">
                      <div class="h6 mb-q mt-1">{{ $t('firewall.fromIP') }}</div>
                      <small
                        v-if="rule.ip_source.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                        v-for="ip in rule.ip_source"
                      >
                        {{ ip }}
                      </small>
                      <small
                        v-if="!rule.ip_source.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                      >
                        -
                      </small>
                    </div>
                    <div class="col-lg-6">
                      <div class="h6 mb-q mt-1">{{ $t('firewall.toIP') }}</div>
                      <small
                        v-if="rule.ip_destination.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                        v-for="ip in rule.ip_destination"
                      >
                        {{ ip }}
                      </small>
                      <small
                        v-if="!rule.ip_destination.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                      >
                        -
                      </small>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      (rule.ip_protocol === 'tcp' || rule.ip_protocol === 'udp') &&
                      (rule.ip_destination_port.length || rule.ip_source_port.length)
                    "
                  >
                    <div class="col-lg-6">
                      <div class="h6 mb-q mt-1">{{ $t('firewall.fromPort') }}</div>
                      <small
                        v-if="rule.ip_source_port.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                        v-for="port in rule.ip_source_port"
                      >
                        {{ port }}
                      </small>
                      <small
                        v-if="!rule.ip_source_port.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                      >
                        -
                      </small>
                    </div>
                    <div class="col-lg-6">
                      <div class="h6 mb-q mt-1">{{ $t('firewall.toPort') }}</div>
                      <small
                        v-if="rule.ip_destination_port.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                        v-for="port in rule.ip_destination_port"
                      >
                        {{ port }}
                      </small>
                      <small
                        v-if="!rule.ip_destination_port.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                      >
                        -
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card animated fadeIn fadeOut border-secondary" v-if="!newFirewallData.rules.length">
                <div class="card-body">
                  <div class="h1 text-right mb-4">
                    <!--<i class="icon-plus text-muted"></i>-->
                    <!--<i class="icon-close text-danger" v-if="rule.jump === 'DROP'"></i>-->
                  </div>
                  <div class="rule-title">
                    <div class="h4 text-muted">{{ $t('firewall.wizardNoRules') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-2">
            <button
              type="button"
              class="btn btn-outline-primary float-right cpe-register-next-button"
              @click="confirmFirewallCreation"
            >
              {{ $t('firewall.confirmButton') }}
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary float-left cpe-register-back-button"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../../helpers';
import firewallService from '../../services/firewallService';
import firewallPollingService from '../../services/firewallPollingService';

export default {
  name: 'FirewallStep3',
  data() {
    return {
      newFirewallData: {}
    };
  },
  computed: {},
  methods: {
    getDirectionTypeWithI18n(typeName) {
      if (this.$t(`firewall.${typeName}`)) {
        return this.$t(`firewall.${typeName}`)
      } else {
        return typeName
      }
    },
    confirmFirewallCreation() {
      this.newFirewallData.rules.forEach((rule) => {
        if (!(rule.ip_protocol === 'tcp' || rule.ip_protocol === 'udp')) {
          rule.ip_destination_port = [];
          rule.ip_source_port = [];
        }
        if (rule.protocol !== 'ipv4') {
          rule.ip_destination = [];
          rule.ip_source = [];
        }
      });
      Vue.axios
        .post(`${window.RESTAPI || ''}/api/network/l2chains`, { action: 'C', items: { 0: this.newFirewallData } })
        .then(
          (response) => {
            if (response.data.status === 'success') {
              firewallPollingService.hadleOperationResponse(response.data.data.items);
              firewallService.getFirewalls(this);
              this.$parent.nextStep();
            } else if (response.data.status === 'error') {
              VueNotifications.error({ message: response.data.description });
              this.$store.commit('toggleLoadingFirewalls', true);
            }
          },
          (err) => {
            VueNotifications.error({ message: err });
            this.error = err;
            this.$store.commit('toggleLoadingFirewalls', true);
          }
        );
    }
  },
  created() {
    this.newFirewallData = JSON.parse(JSON.stringify(this.$store.state.newFirewallData));
  }
};
</script>
<style lang="scss">
.rules .card.not-edit {
  cursor: default;
}
.rules .card.not-edit.border-success:hover {
  background-color: transparent;
}
.rules .card.not-edit.border-danger:hover {
  background-color: transparent;
}
.rules .card.not-edit.border-secondary:hover {
  background-color: transparent;
}
.rules .card.border-secondary:hover {
  background-color: rgb(245, 245, 245);
}
</style>
