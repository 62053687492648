<template>
  <div class="row" v-if="this.$store.state.userData.role !== 'operator'">
    <div class="col-lg-3">
      <section class="card card-inverse firewall-bg">
        <div class="card-block">
          <div class="h1 text-muted text-left mb-2">
            <img class="img img-responsive wizard-wlan-icon" :src="root + '/static/img/brickwall.svg'" alt="" />
          </div>
          <div class="h4 mb-0">{{ $t('firewall.createFirewall') }}</div>
          <div v-if="currentStepIndex === '1'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step1') }}</p>
            <p>{{ $t('firewall.wizard1Caption') }}</p>
          </div>
          <div v-if="currentStepIndex === '2'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step2') }}</p>
            <p>{{ $t('firewall.wizard2Caption') }}</p>
          </div>
          <div v-if="currentStepIndex === '3'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step3') }}</p>
            <p>{{ $t('firewall.wizard3Caption') }}</p>
          </div>
        </div>
      </section>
    </div>
    <div class="col-lg-9"><router-view></router-view></div>
  </div>
</template>

<script>
export default {
  name: 'FirewallWizard',
  computed: {
    root() {
      return window.wimark.root;
    },
    currentStepIndex() {
      return this.$route.name.replace('firewall-step-', '');
    }
  },
  methods: {
    nextStep() {
      let nextStep;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'firewall-step-1':
          nextStep = 'firewall-step-2';
          break;
        case 'firewall-step-2':
          nextStep = 'firewall-step-3';
          break;
        default:
          nextStep = 'Firewall';
      }
      this.$router.push({ name: nextStep });
    },
    prevStep() {
      let prevStep;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'firewall-step-1':
          prevStep = 'Firewall';
          break;
        case 'firewall-step-2':
          prevStep = 'firewall-step-1';
          break;
        case 'firewall-step-3':
          prevStep = 'firewall-step-2';
          break;
        default:
          prevStep = 'Firewall';
      }
      this.$router.push({ name: prevStep });
    }
  },
  beforeCreate() {
    if (this.$store.state.userData.role == 'operator') {
      this.$router.push('../../aps');
    }
  }
};
</script>
<style lang="scss">
.wizard-wlan-icon {
  height: 80px;
}
.firewall-bg {
  background-color: rgb(189, 189, 189);
}
</style>
